.delivery {

}

.delivery-option {
  border: 1px solid $color_gray-2;
  &:last-child {
    border-top: none;
  }
}

.address {
  background-color: $color_white;

  &-box {
    border: 1px solid $color_gray-2;
    justify-content: center;
    padding: 1rem;
  }
  &-item {
    display: block;
    line-height: 2rem;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    &-master {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.3em;
    }
  }
}

@include desktop {
  .delivery, .order-box {
    flex-direction: row;
    justify-content: space-between;
  }
}