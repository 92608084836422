@import "reset";
@import "variables";
//Fonte via google provisóriamente
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

:root {
  font: normal normal 13px/16px "Helvetica Neue", "Helvetica", sans-serif;
  line-height: 1.2rem;
}

#root {
  display: flex;
  flex-direction: row;
  min-width: 100%;
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  //outline: rgba(255,0,0,.2) dashed 1px;
}

body {
  background: $color_gray-1;
  color: $color_gray-4;
}

header,
footer,
div,
form,
p,
h1,
h2,
h3,
h4,
h5,
h6,
nav,
main,
section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

button {
  background: none;
  border: none;
  &:focus {
    outline: 1px dashed #ccc;
  }
}

.hidden {
  display: none !important;
}

@import "components/header";
@import "components/grid";
@import "components/alerts";
@import "components/buttons";
@import "components/footer";
@import "components/inputs";
@import "components/photo_cell";
@import "components/photo_cell_list";
@import "components/status_bar";
@import "components/page";
@import "components/panels";
@import "components/optionselector";
@import "components/cropper";
@import "components/delivery";
@import "components/order";
@import "components/login";
@import "components/my_order";
@import "components/detail_order";
@import "components/personal_data";
@import "components/reset_pass";
@import "components/register";
@import "components/startingPanel";
@import "components/coupon";
@import "components/intro";
@import "components/breadcrumb";
@import "components/post";
@import "components/card.scss";
