
.resume{
  font-family: "Open Sans", sans-serif;
  color: $color-gray-4;
  justify-content: space-between;

  &-box{
    background-color: $color_white;
    width: 100%;
    border: 1px solid $color_gray-2;
    @include border-radius();
    padding: 1rem;
    margin: .5rem 0;
    min-height: 141px;
  }
  &-title{
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }
  &-text{
    font-size: 1rem;
    line-height: 1.5rem;
  }
  &-ship, &-name{
    font-size: 1.1rem;
  }
}
.detail-button{
  width: 100%;
  .button{
    font-weight: bold;
    margin: .5rem 0;
    max-width: 100%;
  }
}

@include desktop {
  .resume{
    flex-direction: row;
    margin-top: 2rem;
    &-box{
      width: 49%;
    }
  }
  .detail-button{
    margin-top: 1rem;
    flex-direction: row;
    .button{
      margin: 1rem 1.5rem;
      max-width: 240px;
      &:first-child{
        margin-left: 0;
      }
    }
  }
}