.coupon-box {
  border: 1px solid $color_primary;
  background: lighten($color_primary, 40%);
  color: darken($color_primary, 20%);
  font-weight: bold;
  text-transform: uppercase;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 3px;

  p {
    font-size: 1rem;
    line-height: 1.5rem;

    &.coupon-value {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
}

.coupon-box.coupon-invalid {
  border-color: $color_secondary;
  background: lighten($color_secondary, 40%);
  color: darken($color_secondary, 20%);
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}