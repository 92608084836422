.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  width: 100%;
  background-color: $color_primary;
  color: $color_white;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: .5rem;
  border: none;
  cursor: pointer;
  box-shadow: 1px 2px 4px $color_gray-3;
  transition: all 200ms ease-in-out;
  @include border-radius();

  &:hover {
    background-color: lighten($color_primary, 5%);
    box-shadow: 1px 2px 4px $color_gray-4;
  }

  &:active {
    background-color: darken($color_primary, 5%);
    box-shadow: 1px 2px 4px $color_gray-4 inset;
  }

  &-ghost{
    color: $color_gray-4;
    background: inherit;

    &:hover {
      background-color: lighten($color_gray-2, 5%);
      box-shadow: 1px 2px 4px $color_gray-4;
    }

    &:active {
      background-color: darken($color_gray-2, 5%);
      box-shadow: 1px 2px 4px $color_gray-4 inset;
    }

  }

  &.disabled {
    background-color: $color_gray-3;
    cursor: not-allowed;
    box-shadow: none;
  }

  svg {
    margin-right: 10px;
  }

  &.loading {

    span {
      display: none;
    }
    &:after {
      content: '';
      display: block;
      height: 20px;
      width: 20px;
      @include background_image('spinner.svg');
      background-size: contain;
      margin: 0 auto;
    }
  }
}

.button-next {
  align-items: center;
}

.button-panel {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #DBDBDB;
  background: #E5E5E5;
  border-radius: 4px;
  padding: 1rem 1rem;
  margin: 2rem 0 0;

  .button {
    margin: 0;
  }
}

@include desktop {
  .button {
    max-width: 250px;
  }
  .button-next {
    padding-top: 10px;
  }
}