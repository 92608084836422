.lost-pass, .reset-pass{
  &-text{
    font-size: 1.3rem;
    color: $color_primary;
    text-align: center;
    margin: 5rem 0;
  }
}

.icon-success{
  @include background_image('icon-success.svg');
  display: block;
  width: 100px;
  height: 100px;
  margin: 2rem auto 0;
  opacity: 1;
  animation: icon-success 1s;
  -webkit-animation: icon-success 1s;
}

@keyframes icon-success{
 0% { opacity: 0;}
 100% { opacity: 1;}
}
