.form-input {
	padding: 0.5rem;
	background-color: $color_gray-1;
	outline: none;
	border: 1px solid $color_gray-2;
	@include transition(border);

	&[type="text"],
	&[type="password"],
	&[type="email"],
	&[type="date"] {
		&:focus {
			border-color: $color_primary;
		}
	}

	&[type="checkbox"],
	&[type="radio"] {
		&:focus {
			outline: $color-primary dashed 1px;
		}
	}

	&[disabled] {
		cursor: not-allowed;
		color: $color_gray-3;
	}

	&[readonly] {
		color: $color_gray-3;
	}

	&.error {
		border-color: $color-secondary !important;
		background: lighten($color-secondary, 40%);
	}
}

.form-input-wrapper {
	margin-bottom: 1rem;
}

.form-option-wrapper {
	margin-bottom: 1rem;
}

.form-label {
	font-family: "Open Sans", sans-serif;
	padding: 0.25rem 0;
	font-size: 1rem;
}

.form-zipcode {
	margin-bottom: 1rem;
}

.zipcode-wrapper {
}

.zipcode-wrapper,
.cupom-wrapper {
	flex-direction: row;
}

.zipcode-title {
	display: block;
	margin-bottom: 0.5rem;
}

.form-validation {
	padding: 2px;
	color: $color-secondary;
	font-weight: bold;
}

.zipcode,
.cupom {
	margin: 0;
	box-shadow: none;
	& + button {
		text-transform: capitalize;
		@include border-radius(0 3px 3px 0);

		&:hover {
			box-shadow: 3px 3px 10px $color_gray-4;
		}
		span {
			display: block;
			padding: 0;
		}
	}
}

.card-number {
}

.card-name {
	text-transform: uppercase;
}

.box-data-card {
	flex-direction: row;
	align-items: flex-end;
	justify-content: start;
}

.data-card {
	margin: 1rem;
	&.month {
    margin-left: 0;
    margin-right: 0;
		width: 60px;
	}
	&.year {
		width: 60px;
    margin-right: 0;
	}
	&.cvv {
		width: 50px;
    margin-right: 0;
	}
	&.installment {
		width: 110px;
    margin-right: 0;
	}
}

input.form-input,
select.form-input {
	font-size: 1.1rem;
	font-family: "Open Sans", sans-serif;
	border-radius: 0;
	background: $color-gray-1;
}

select.form-input {
	-webkit-appearance: none;
	@include background_image("select-arrow.svg", $color-gray-1);
	background-repeat: no-repeat;
	background-position: center right;

	&:focus {
		border-color: $color_primary;
	}
}

input[type="text"].form-input {
}

input[type="password"].form-input {
}

input[type="email"].form-input {
}

.form-gift {
	display: block;
	width: 200px;
	margin: 0 auto;
}
