.photo-cell {
  width: 186px;
  //background-color: $color_white;
  align-items: center;
  margin: 10px;
}

.photo-cell-holder {
  position: relative;
  height: 186px;
  width: 186px;

  &.low-resolution:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 6px;
    right: 5px;
    width: 40px;
    height:40px;
    @include background_image('lowres.png');
    background-size: cover;
    z-index: 106;
  }

  &:after {
    content: '';
    @include background_image('picture_holder.png');
    background-size: cover;
    width: 186px;
    height: 184px;
    position: absolute;
    top: 1px;
    left: 0;
    z-index: 100;
  }
  .photo-cell-button-add {
    background: $color_primary;
    width: 181px;
    height: 180px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    z-index: 101;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      @include background_image('icon-plus.svg');
      position: absolute;
      width: 40px;
      height: 94px;

    }

    .photo-cell-button-text {
      color: $color_white;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 1.3rem;
      text-align: center;
    }
  }
}

.photo-cell-image {
  width: 180px;
  height: 180px;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 10;
}

.photo-cell-actions {
  width: 186px;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 4px;
}

.photo-cell-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 90px;
  min-height: 26px;
  color: $color_white;
  cursor: pointer;
  @include border-radius();

  .photo-cell-button-icon {
    margin-right: 6px;
    padding-bottom: 1px;
  }

  .photo-cell-button-text {
    display: block;
    text-align: center;
    padding-bottom: 1px;
  }
}

.photo-cell-button-edit {
  background: $color-primary;
  &:hover {
    background: darken($color-primary, 10%);
  }
}

.photo-cell-button-remove {
  background: $color-secondary;
  &:hover {
    background: darken($color-secondary, 10%);
  }
}

.photo-cell-copies {
  padding-top: 10px;
}

.photo-cell-button-plus, .photo-cell-button-minus {
  background: $color-primary;
  width: 25px;
  height: 25px;
  &:hover {
    background: darken($color-primary, 10%);
  }
}

.photo-cell-quantity {
  flex: 0.8;
  background: $color-gray-2;
  padding: 7px;
  text-align: center;
  font-style: italic;
  color: $color-gray-4;
  overflow: hidden;
  @include border-radius(8px);
}
