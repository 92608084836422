@font-face {
  font-family: "Lato";
  src: url(#{'../fonts/Lato-Light.ttf'});
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url(#{'../fonts/Lato-Regular.ttf'});
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url(#{'../fonts/Lato-Black.ttf'});
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url(#{'../fonts/Lato-Bold.ttf'});
  font-weight: bold;
  font-style: normal;
}

:root {
  font-size: 14px;
}

@media (min-width: 1023px), (orientation: landscape) {

  :root {
    font-size: 16px;
    line-height: 20px;
  }

}

body {
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: .9rem;
  line-height: 120%;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  margin-bottom: 1rem;
  font-family: "Lato", sans-serif;
  text-align: center;
}

h1 {
  font-size: 1.3rem;
  line-height: 1.5rem;
}

h2 {
  font-size: 1.25rem;
  line-height: 1.45rem;
  font-weight: 400;
}

h3 {
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 600;
}

h4 {
  font-size: 1.15rem;
  line-height: 1.35rem;
}

h5 {
  font-size: 1.1rem;
  line-height: 1.3rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.25rem;
}

strong {
  font-weight: bolder;
}
