.alert{
    &-box{
        width: 90%;
        min-width: 300px;
        max-width: 380px;
        min-height: 90px;
        top: 100px;
        right: 10px;
        position: fixed;
        background: $color_bg-alert;
        border: 1px solid $color_secondary;
        @include box-sizing(border-box);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        z-index:600;
    }
    &-box:before{
        content: '';
        @include background_image('icon-alert.svg');
        background-size: contain;
        display: block;
        width: 38px;
        margin: 0 1rem;
    }
    &-close{
        @include background_image('icon-close.svg');
        background-size: contain;
        display: block;
        width: 26px;
        height: 26px;
        margin-top: 10px;
        margin-right: 14px;
        cursor: pointer;
        span{
            display: none;
        }
    }
    &-content{
        max-width: 259px;
        display: block;
    }
    &-title{
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: bold;
        line-height: 24px;
        margin: .5rem 0;
        font-size: 18px;
        color: $color_secondary;
    }
    &-description{
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        line-height: 1.5rem;
        font-size: 14px;
        padding-bottom: 1rem;
        color: $color_secondary;
    }
    &-off{
        display: none;
    }
}

@include desktop{
    .alert{
        &-box{
            top: 47px;
        }
    }
}