@media (min-width: 1023px), (orientation: landscape) {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {

  }

  h1 {
    font-size: 2.5rem;
    line-height: 2.6rem;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 2.2rem;
    line-height: 2.4rem;
    margin-bottom: 1.8rem;
  }

  h3 {
    font-size: 2.1rem;
    line-height: 2.2rem;
    margin-bottom: 1.6rem;
  }

  h4 {
    font-size: 2rem;
    line-height: 2.1rem;
    margin-bottom: 1.5rem;
  }

  h5 {
    font-size: 1.8rem;
    line-height: 2rem;
    margin-bottom: 1.4rem;
  }

  h6 {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 1.2rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 1.2rem;
  }

  .grid-row {
    display: block;
  }

  .grid-row:after {
    content: '';
    display: block;
    clear: both;
  }

  .grid-cell-4 {
    display: inline-block;
    width: 33.333333%;
    float: left;
    padding: 1rem;
  }

  .container {
    max-width: 800px;
    margin: 0 auto;
  }

  .container:after {
    content: '';
    display: block;
    clear: both;
  }

  header {
    height: 38rem;
    background-position: top center;
  }

  header .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10rem;
    max-width: 1024px;
    padding: 2rem;
    background: #ffffffe8;
  }

  header .site-name {
    width: 230px;
    height: 94px;
    margin: 1rem;
    display: inline-block;
    float: left;
  }

  header .site-subtitle {
    display: inline-block;
    flex: 1;
    background: none;
    color: #5b2c8f;
    text-align: left;
    font-size: 2rem;
    line-height:2.3rem;
    padding: 0;
    margin: 0 0 0 1rem;
    border-left: 5px solid #f17e20;
    padding-left: 2rem;
    font-weight: 300;
  }

  .content .intro {
    min-height: 520px;
    padding-top: 0;
    background-position: top center;
  }

  .content .intro h2 {
    display: none;
  }

  .content .links {
    //width: 360px;
  }

  .content .links .store-link {
    width: 150px;
    height: 40px;
    margin: 1rem;
  }

  .content .preview {
    padding: 4rem .5rem;
  }

  .content .preview .smartphone {
    width: 500px;
    height: 231px;
    margin: 2rem auto;
  }

  .content .preview p {
    clear: both;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
  }

  .content .highlight {
    padding: 19rem .5rem;
  }

  .content .highlight h2 {

  }

  .content .highlight p {

  }

  .content .differences {
    @include background_image("bg-1.gif");
  }

  .content .examples {
    min-height: 50rem;
  }

  .content .differences,
  .content .examples,
  .content .faq {
    padding: 5rem .5rem;
  }

  .content .faq h3 {
    margin-top: 5rem;
  }

  @media (min-width: 1800px) {
    header {
      height: 58rem;
    }

    header .container {
      margin-top: 20rem;
    }
  }
}