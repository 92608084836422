$header_height: 50px;

.header {
  width: 100%;
  padding: 0 1rem;
  background: $color_white;
  border-bottom: 1px solid $color_gray-2;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;

  @include desktop {
    position: inherit;
  }

  .header-logo {
    justify-content: center;
    text-decoration: none;
    padding: 0;
    margin: 0;
    height: $header_height;
    width: 80px;
  }
  .logo-img {
    @include background_image('logo.svg');
    background-size: contain;
    height: 30px;
    width: 80px;
    span {
      display: none;
    }
  }

  .menu-button {
    justify-content: center;
    align-items: center;
    height: $header_height;
    width: $header_height;

    &:hover {
      background-color: $color_gray-2;
    }

    &:after {
      content: '';
      display: block;
      height: $header_height;
      width: $header_height;
      @include background_image('icon-user.svg');
    }

    span {
      display: none;
    }
  }

}

.menu {
  display: block;
  position: absolute;
  background-color: $color_white;
  width: 280px;
  top: 0;
  right: 0;
  color: $color_primary;

  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: $color_white;
    border-left-width: 25px;
    border-top-width: 25px;
    position: absolute;
    top: -10px;
    right: 20px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -o-transform: rotate(45deg);
  }
  .modal {
    position: absolute;
    top: 0;
    right: 5px;
    width: 250px;
    background-color: $color_white;
    text-align: center;

    .name-user {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: bold;
      text-align: left;
      padding: 1.5rem;
      border-bottom: 1px solid $color_gray-2;
    }
    .menu-list {
      border-bottom: 1px solid $color_gray-2;
      padding: 1rem 0;
      .menu-item {
        padding: 1rem;
      }
    }
    a {
      display: inline-block;
      text-decoration: none;
      color: $color_primary;
    }
    .exit {
      padding: 1.5rem;
    }
  }
}

.Overlay {
  position: fixed;
  z-index: 102;
  top: 53px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
}

.droppingContent {
  z-index: 200;
  background-color: #F57F1E30;
}