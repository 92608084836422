@import "variables";

#root .home {
  background-color: #fff;

  @import "home/reset";
  @import "home/structure";
  @import "home/layout";
  @import "home/desktop";
  @import "home/fonts";
}