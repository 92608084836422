.app-container {

  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .wrapper {
    height: 100%;
    width: 100%;
    padding: 1rem .5rem;

    @include desktop {
      max-width: $desktop-width;
    }

    &.loading {
      position: relative;
      &:after {
        content: 'Carregando, aguarde...';
        font-size: 1rem;
        font-weight: bold;
        color: $color-gray-4;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include background_image('spinner.svg', rgba($color_gray-1, .5), center, 40%);
        z-index: 500;
      }
    }

  }

}

.container-content {
  flex:1;
  align-items: center;
  justify-content: flex-start;
  padding-top: 4rem;
  @include desktop {
    padding-top: inherit;
  }
}