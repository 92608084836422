.cropper-holder {
  width: 100%;
  background: $color_white;
  border: 1px solid $color_gray-2;
  padding: 1rem;

}

.cropper-container {
  width:100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  @include desktop{
    width: 500px;
    height: 500px;
  }

  &>div{
    border: 1px solid $color_gray-4;
  }
}
