.post-content {
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin-bottom: 2rem;
    margin-top: 3rem;
    font-size: 2rem;
    line-height: 2.3rem;
  }
  h2 {
    margin-bottom: 1.8rem;
    margin-top: 2rem;
    font-size: 1.8rem;
    line-height: 2rem;
  }

  h3 {
    margin-bottom: 1.8rem;
    margin-top: 2rem;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  h4 {
    margin-bottom: 1.8rem;
    margin-top: 2rem;
    font-size: 1.5rem;
    line-height: 1.7rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }

  .mb {
    font-size: 3rem;
    line-height: 3.5rem;
    padding: 1rem 0;
    border-bottom: 10px solid $color-secondary;
    color: $color-primary;
  }
}