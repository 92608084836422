.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background: $color_white;
  border: 1px solid $color_gray-1;

  h3 {
    text-align: center;
    font-size: 2rem;
    line-height: 2.3rem;
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    font-size: 1rem;
    line-height: 1.4rem;
  }
}

.intro-image {
  display: block;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 450px;
}