.option-selector {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//Escondendo radio do input
.option-selector .input-option {
  position: absolute;
  opacity: 0;
}

.option-content {
  flex-direction: column;
}

//Radio customizado
.checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: $color_gray-2;
  border-radius: 50%;
  margin-right: 20px;
  &.-delivery-info {
    height: 31.5%;
  }
}

// Bola interna em estado padrão
.checkmark::before {
  content: "";
  position: absolute;
  display: block;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $color_white;
}

// Tom de roxo claro no hover
.option-selector.checked .checkmark {
  background-color: $color_primary_light;
  height: 20px;
  width: 20px;

  &.-delivery-info {
    height: 31.5%;
  }
}

//Borda cinza em estado check
.option-selector:hover .checkmark {
  height: 20px;
  width: 20px;
  background-color: $color_primary_light;
  &.-delivery-info {
    height: 31.5%;
  }
}

//Marcador
.checkmark:after {
  content: "";
  position: absolute;
  display: block;
}

//Mostra marcador roxo
.option-selector.checked .checkmark:after {
  display: block;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: $color_primary;
}

//Texto maior do Option
.option-title {
  display: block;
  color: $color_primary;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 10px;
}

//Texto menor
.option-text {
  display: block;
  color: $color_primary;
  font-size: 1.1rem;
}
