.cards-list {
	z-index: 0;
	width: 100%;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	flex-direction: row;
}

.card {
	position: relative;
	margin: 1rem auto;
	width: 320px;
	height: 320px;
	cursor: pointer;
	transition: 0.4s;
	&:hover {
		transform: scale(0.9, 0.9);
		box-shadow: 5px 5px 5px 5px rgba(80, 76, 83, 0.2),
			-5px -5px 5px 5px rgba(80, 76, 83, 0.2);
	}
}

.card .card_image {
	width: inherit;
	height: inherit;
}

.card .card_image img {
	width: inherit;
	height: inherit;
	object-fit: cover;
}

.card .card_title {
	text-align: center;
	font-family: sans-serif;
	font-weight: bold;
	height: 40px;
	font-size: 28px;
	margin-top: 8px;
}

.title-white {
	font-size: 30px;
	color: white;
}

.subtitle-white {
	color: white;
	font-size: 24px;
}

.title-black {
	color: black;
}

.card-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.card-bottom {
	position: absolute;
	bottom: 10px;
}

@media all and (max-width: 500px) {
	.card-list {
		/* On small screens, we are no longer using row direction but column */
		flex-direction: column;
	}
}
