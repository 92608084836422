.panel-default {
  background-color: $color_white;
  border: 1px solid $color_gray-2;
  margin-bottom: .5rem;
  @include box-sizing(border-box);
  @include border-radius();
}

.panel-default-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  min-height: 40px;
  border-bottom: 1px solid $color_gray-2;
  color: $color_primary;
  font-weight: bold;
  font-size: 1.3rem;
}

.panel-default-content {
  padding: 1rem;
}

.panel-horizontal {
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.panel-vertical {
  align-items: center;
  margin-bottom: 1rem;
}

.panel-photolist {
  flex-flow: row wrap;
  justify-content: center;
  //background: $color_white;
}

@include desktop {
  .panel-default-6 {
    max-width: 48%;
  }
  .panel-default-4 {
    max-width: 33%;
  }
  .panel-default-3 {
    max-width: 24%;
  }

  .panel-photolist {
    justify-content: flex-start;
    padding: 0;
  }
}
