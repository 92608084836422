.wrapper {
  margin: 0;
  padding: 0;
  width: 100%;
}

header {
  min-height: 80px;
}

.content {

}

footer {
  min-height: 100px;
  color: #fff;
}


