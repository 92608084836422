.footer {
  background: $color_white;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-top:1px solid $color_gray-2;
}

.footer-content {
  align-items: center;
}

.footer-cell {
  padding: 1rem 0;
  align-items: center;
}

.footer-cell-logo {
  flex-direction: row;
  justify-content: center;
  padding: 1rem;
}

.footer-small-message {
  font-size: 1rem;
  padding: 1rem 0;
  border-top: 1px solid $color_gray-2;
  text-align: center;
}

.footer-info {
  font-size: 1rem;
  line-height: 1.5rem;
  width: 65%;
  max-width: 200px;
  margin-left: 1rem;
  color: $color_primary;
}

.footer-logo {
  @include background_image('logo.svg');
  background-size: contain;
  width: 80px;
  height: 40px;
  margin-bottom: .5rem;

  span {
    display: none;
  }
}

.footer-title {
  color: $color_primary;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: bold;
  padding: .25rem 0;
  text-align: center;
  margin-bottom: .5rem;
}

.footer-link {
  color: $color_primary;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  padding: .25rem 0;
}
.footer-social{
  display: flex;
  justify-content: space-between;
  width: 60px;
}

.footer-social-twitter{
  @include background_image('icon-twitter.svg');
  background-size: contain;
  display: block;
  width: 24px;
  height: 20px;
  span{
    display: none;
  }
}
.footer-social-instagram{
  @include background_image('icon-instagram.svg');
  background-size: contain;
  width: 24px;
  height: 24px;
  display: block;
  span{
    display: none;
  }
}

.footer-appstore{
  display: flex;
}

.footer-appstore-apple{
  @include background_image('app-store.svg');
  background-size: contain;
  width: 106px;
  height: 30px;
  display: block;
  span{
    display: none;
  }
}


.footer-appstore-google{
  @include background_image('google-play.svg');
  background-size: contain;
  width: 106px;
  height: 30px;
  display: block;
  span{
    display: none;
  }
}


@include desktop {
  .footer-content {
    flex-direction: row;
  } 
}