.order {
  color: $color_primary;

  &-box {
    background-color: $color_white;
    border: 1px solid $color_gray-2;
    @include border-radius();
    max-width: 700px;
    width: 95%;
    margin: 0 auto;
    align-items: center;

    .order-col {
      &:first-child {
        width: 30%;
        min-width: 184px;
      }
      .product-image {
        display: block;
        @include background_image('deliverybox.png');
        background-size: contain;
        width: 184px;
        height: 128px;

        span {
          display: none;
        }
      }

    }
  }
  &-table {
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-size: 18px;
    min-width: 280px;
    width: 90%;
    margin: 0 1rem;

    &-line {
    }
  }
  &-td {
    width: 65%;
    padding: 1.5rem .5rem;
    line-height: 2rem;
    strong {
      display: inline-block;
      font-weight: bold;
    }
  }
  &-info {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    width: 90%;
    margin: 1rem auto;

    a {
      text-decoration: none;
      color: $color_gray-4;

      &:hover{
        text-decoration: underline;
      }
    }

    p {
      line-height: normal;
      margin: 1rem 0;
      span {
        font-weight: bold;
      }
    }
    &-p {
      font-size: 1.4rem;

      &-strong {
        font-size: 1.4rem;
        font-weight: bold;
      }
    }
    &-attention {
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 1rem;
      text-align: center;
    }
  }
}

@include desktop {
  .order-info {
    width: 60%;
  }
}