//Colors
$color_white: #FFFFFF;
$color_gray-1: #F5F5F5;
$color_gray-2: #E5E5E5;
$color_gray-3: #9E9BA1;
$color_gray-4: #49454E;
$color_primary: #5C2B8F;
$color_primary_light: #CFC2DC;
$color_secondary: #F57F1E;
$color_bg-alert: #FFFAF6;
$color_progress: #8FEC57;

$desktop-width: 1060px;
$transitionTime: 200ms;

@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
     -moz-box-sizing: $box-model; // Firefox <= 19
          box-sizing: $box-model;
}

@mixin border-radius($radius:3px){
-webkit-border-radius: $radius;
   -moz-border-radius: $radius;
    -ms-border-radius: $radius;
     -o-border-radius: $radius;
}

@mixin transition($prop: all) {
  -webkit-transition: $prop $transitionTime;
  -moz-transition: $prop $transitionTime;
  -o-transition: $prop $transitionTime;
  transition: $prop $transitionTime;
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin background_image($image_name, $color:transparent, $positionY: center, $positionX: center, $repeat: no-repeat) {
  background: $color url(#{'../images/' + $image_name}) $positionY $positionX $repeat;
}