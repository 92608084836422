@import url(https://fonts.googleapis.com/css?family=Open+Sans);
#root .home {
  background-color: #fff;
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
  /* HTML5 display-role reset for older browsers */ }
  #root .home html, #root .home body, #root .home div, #root .home span, #root .home applet, #root .home object, #root .home iframe,
  #root .home h1, #root .home h2, #root .home h3, #root .home h4, #root .home h5, #root .home h6, #root .home p, #root .home blockquote, #root .home pre,
  #root .home a, #root .home abbr, #root .home acronym, #root .home address, #root .home big, #root .home cite, #root .home code,
  #root .home del, #root .home dfn, #root .home em, #root .home img, #root .home ins, #root .home kbd, #root .home q, #root .home s, #root .home samp,
  #root .home small, #root .home strike, #root .home strong, #root .home sub, #root .home sup, #root .home tt, #root .home var,
  #root .home b, #root .home u, #root .home i, #root .home center,
  #root .home dl, #root .home dt, #root .home dd, #root .home ol, #root .home ul, #root .home li,
  #root .home fieldset, #root .home form, #root .home label, #root .home legend,
  #root .home table, #root .home caption, #root .home tbody, #root .home tfoot, #root .home thead, #root .home tr, #root .home th, #root .home td,
  #root .home article, #root .home aside, #root .home canvas, #root .home details, #root .home embed,
  #root .home figure, #root .home figcaption, #root .home footer, #root .home header, #root .home hgroup,
  #root .home menu, #root .home nav, #root .home output, #root .home ruby, #root .home section, #root .home summary,
  #root .home time, #root .home mark, #root .home audio, #root .home video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline; }
  #root .home article, #root .home aside, #root .home details, #root .home figcaption, #root .home figure,
  #root .home footer, #root .home header, #root .home hgroup, #root .home menu, #root .home nav, #root .home section {
    display: block; }
  #root .home body {
    line-height: 1; }
  #root .home ol, #root .home ul {
    list-style: none; }
  #root .home blockquote, #root .home q {
    quotes: none; }
  #root .home blockquote:before, #root .home blockquote:after,
  #root .home q:before, #root .home q:after {
    content: '';
    content: none; }
  #root .home table {
    border-collapse: collapse;
    border-spacing: 0; }
  #root .home .wrapper {
    margin: 0;
    padding: 0;
    width: 100%; }
  #root .home header {
    min-height: 80px; }
  #root .home footer {
    min-height: 100px;
    color: #fff; }
  #root .home header {
    background: transparent url(/static/media/adventure-2679498_1920.018a8c3d.jpg) center center no-repeat;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom center;
    min-height: 16rem;
    padding: 1rem;
    border-bottom: 20px solid #f17e20; }
  #root .home header .site-name {
    background: transparent url(/static/media/logo.c53d91bd.svg) center center no-repeat;
    background-size: contain;
    display: block;
    width: 140px;
    height: 57px;
    margin: 1rem auto 2rem;
    padding: 0; }
  #root .home header .site-subtitle {
    font-size: 1.2rem;
    color: #5c2d91;
    background-color: #ffffffe8;
    padding: 1rem; }
  #root .home header .site-subtitle:after,
  #root .home header .site-subtitle:before {
    content: '  '; }
  #root .home .dashed:before,
  #root .home .dashed:after {
    content: '';
    width: 70%;
    display: block;
    margin: 1rem auto;
    border-top: 1px solid #f5f5f5; }
  #root .home header .site-name span {
    display: none; }
  #root .home .content {
    margin: 0 auto; }
  #root .home .content .intro h2 {
    font-family: "Lato", sans-serif;
    color: #fff;
    background-color: #f17e20c7;
    padding: 1rem;
    font-size: 1.2rem;
    line-height: 140%;
    margin: 0; }
  #root .home .content .preview {
    padding: 2rem .5rem;
    border-bottom: 20px solid #5b2c8f; }
  #root .home .content .preview .smartphone {
    content: '';
    display: block;
    background: transparent url(/static/media/preview.c0236798.png) center center no-repeat;
    background-size: contain;
    width: 250px;
    height: 117px;
    margin: 1rem auto; }
  #root .home .content .preview:after {
    content: '';
    display: block;
    clear: both; }
  #root .home .content .links {
    margin: 0 auto 1rem;
    align-items: center;
    justify-content: center; }
  #root .home .content .links:after {
    content: '';
    display: block;
    clear: both; }
  #root .home .content .links .store-link {
    display: inline-block;
    width: 100px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: .5rem; }
  #root .home .content .links .store-link span {
    display: none; }
  #root .home .content .links .store-link.store-apple {
    display: inline-block;
    overflow: hidden;
    background: transparent url(/static/media/appstore-lrg.1cfd5dba.svg) center center no-repeat;
    background-size: contain; }
  #root .home .content .links .store-link.store-android {
    background: transparent url(/static/media/google-play.a9c66b43.png) center center no-repeat;
    background-size: contain; }
  #root .home .content .links .store-link.store-webapp {
    background: transparent url(/static/media/badge_online.a336d175.png) center center no-repeat;
    background-size: contain;
    width: 250px;
    height: 65px; }
  #root .home .content .highlight {
    background: #ccc url(/static/media/highlight.5a4a0f4a.jpg) center center no-repeat;
    background-size: cover;
    min-height: 100px;
    padding: 2rem .5rem;
    color: #fff;
    text-align: center; }
  #root .home .content .differences {
    padding: 2rem .5rem;
    text-align: center; }
  #root .home .content .differences h4 {
    font-size: 1.7rem; }
  #root .home .content .examples {
    padding: 2rem .5rem;
    background: transparent url(/static/media/bg-2.adbc9095.jpg) top center no-repeat;
    background-size: cover;
    min-height: 23rem; }
  #root .home .content .examples:after {
    content: '';
    display: block;
    clear: both; }
  #root .home .content .examples img {
    display: block;
    margin: .5rem auto;
    max-width: 50%;
    float: left; }
  #root .home .content .faq {
    padding: 2rem 2rem;
    text-align: center; }
  #root .home .content .faq h3 {
    padding: 1rem;
    margin-top: 2rem; }
  #root .home .content .faq p {
    margin: 0 auto; }
  #root .home .content .faq p:after {
    content: '';
    display: block;
    width: 40%;
    margin: 2rem auto 1rem;
    border-top: 1px solid #e5e5e5; }
  #root .home .content .faq p:before {
    content: '';
    display: block;
    width: 40%;
    margin: 1rem auto 2rem;
    border-top: 1px solid #e5e5e5; }
  #root .home footer {
    padding: 2rem .5rem;
    margin: 0;
    background-color: #5c2d91; }
  #root .home footer a {
    color: #fff;
    text-decoration: none; }
  #root .home footer h5 {
    font-size: 1rem; }
  #root .home footer .rights {
    font-size: .8rem; }
  #root .home table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem; }
  #root .home table th,
  #root .home table td {
    padding: .5rem;
    border: 1px solid #e5e5e5;
    text-align: center; }
  #root .home table th {
    background-color: #e5e5e5; }
  #root .home .terms {
    padding: 1rem; }
    #root .home .terms h2 {
      font-size: 120%;
      margin-bottom: 1rem; }
    #root .home .terms p {
      margin-bottom: 1rem; }
  @media (min-width: 1023px), (orientation: landscape) {
    #root .home h1 {
      font-size: 2.5rem;
      line-height: 2.6rem;
      margin-bottom: 2rem; }
    #root .home h2 {
      font-size: 2.2rem;
      line-height: 2.4rem;
      margin-bottom: 1.8rem; }
    #root .home h3 {
      font-size: 2.1rem;
      line-height: 2.2rem;
      margin-bottom: 1.6rem; }
    #root .home h4 {
      font-size: 2rem;
      line-height: 2.1rem;
      margin-bottom: 1.5rem; }
    #root .home h5 {
      font-size: 1.8rem;
      line-height: 2rem;
      margin-bottom: 1.4rem; }
    #root .home h6 {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 1.2rem; }
    #root .home p {
      font-size: 1.2rem;
      line-height: 1.6rem;
      margin-bottom: 1.2rem; }
    #root .home .grid-row {
      display: block; }
    #root .home .grid-row:after {
      content: '';
      display: block;
      clear: both; }
    #root .home .grid-cell-4 {
      display: inline-block;
      width: 33.333333%;
      float: left;
      padding: 1rem; }
    #root .home .container {
      max-width: 800px;
      margin: 0 auto; }
    #root .home .container:after {
      content: '';
      display: block;
      clear: both; }
    #root .home header {
      height: 38rem;
      background-position: top center; }
    #root .home header .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10rem;
      max-width: 1024px;
      padding: 2rem;
      background: #ffffffe8; }
    #root .home header .site-name {
      width: 230px;
      height: 94px;
      margin: 1rem;
      display: inline-block;
      float: left; }
    #root .home header .site-subtitle {
      display: inline-block;
      flex: 1 1;
      background: none;
      color: #5b2c8f;
      text-align: left;
      font-size: 2rem;
      line-height: 2.3rem;
      padding: 0;
      margin: 0 0 0 1rem;
      border-left: 5px solid #f17e20;
      padding-left: 2rem;
      font-weight: 300; }
    #root .home .content .intro {
      min-height: 520px;
      padding-top: 0;
      background-position: top center; }
    #root .home .content .intro h2 {
      display: none; }
    #root .home .content .links .store-link {
      width: 150px;
      height: 40px;
      margin: 1rem; }
    #root .home .content .preview {
      padding: 4rem .5rem; }
    #root .home .content .preview .smartphone {
      width: 500px;
      height: 231px;
      margin: 2rem auto; }
    #root .home .content .preview p {
      clear: both;
      max-width: 400px;
      margin: 0 auto;
      text-align: center; }
    #root .home .content .highlight {
      padding: 19rem .5rem; }
    #root .home .content .differences {
      background: transparent url(/static/media/bg-1.8947f9fb.gif) center center no-repeat; }
    #root .home .content .examples {
      min-height: 50rem; }
    #root .home .content .differences,
    #root .home .content .examples,
    #root .home .content .faq {
      padding: 5rem .5rem; }
    #root .home .content .faq h3 {
      margin-top: 5rem; } }

@media (min-width: 1023px) and (min-width: 1800px), (orientation: landscape) and (min-width: 1800px) {
  #root .home header {
    height: 58rem; }
  #root .home header .container {
    margin-top: 20rem; } }

@font-face {
  #root .home {
    font-family: "Lato";
    src: url(/static/media/Lato-Light.5b761f2d.ttf);
    font-weight: 200;
    font-style: normal; } }

@font-face {
  #root .home {
    font-family: "Lato";
    src: url(/static/media/Lato-Regular.7f690e50.ttf);
    font-weight: normal;
    font-style: normal; } }

@font-face {
  #root .home {
    font-family: "Lato";
    src: url(/static/media/Lato-Black.77d35374.ttf);
    font-weight: 700;
    font-style: normal; } }

@font-face {
  #root .home {
    font-family: "Lato";
    src: url(/static/media/Lato-Bold.44dfe8cc.ttf);
    font-weight: bold;
    font-style: normal; } }
  #root .home :root {
    font-size: 14px; }
  @media (min-width: 1023px), (orientation: landscape) {
    #root .home :root {
      font-size: 16px;
      line-height: 20px; } }
  #root .home body {
    font-family: "Lato", sans-serif;
    font-weight: normal;
    font-size: .9rem;
    line-height: 120%;
    -webkit-font-smoothing: antialiased; }
  #root .home h1, #root .home h2, #root .home h3, #root .home h4, #root .home h5, #root .home h6 {
    font-weight: 300;
    margin-bottom: 1rem;
    font-family: "Lato", sans-serif;
    text-align: center; }
  #root .home h1 {
    font-size: 1.3rem;
    line-height: 1.5rem; }
  #root .home h2 {
    font-size: 1.25rem;
    line-height: 1.45rem;
    font-weight: 400; }
  #root .home h3 {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 600; }
  #root .home h4 {
    font-size: 1.15rem;
    line-height: 1.35rem; }
  #root .home h5 {
    font-size: 1.1rem;
    line-height: 1.3rem; }
  #root .home h6 {
    font-size: 1rem;
    line-height: 1.25rem; }
  #root .home strong {
    font-weight: bolder; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

:root {
  font: normal normal 13px/16px "Helvetica Neue", "Helvetica", sans-serif;
  line-height: 1.2rem; }

#root {
  display: flex;
  flex-direction: row;
  min-width: 100%;
  min-height: 100vh; }

html {
  scroll-behavior: smooth; }

* {
  box-sizing: border-box; }

body {
  background: #F5F5F5;
  color: #49454E; }

header,
footer,
div,
form,
p,
h1,
h2,
h3,
h4,
h5,
h6,
nav,
main,
section {
  display: flex;
  flex-direction: column;
  width: 100%; }

button {
  background: none;
  border: none; }
  button:focus {
    outline: 1px dashed #ccc; }

.hidden {
  display: none !important; }

.header {
  width: 100%;
  padding: 0 1rem;
  background: #FFFFFF;
  border-bottom: 1px solid #E5E5E5;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500; }
  @media (min-width: 1060px) {
    .header {
      position: inherit; } }
  .header .header-logo {
    justify-content: center;
    text-decoration: none;
    padding: 0;
    margin: 0;
    height: 50px;
    width: 80px; }
  .header .logo-img {
    background: transparent url(/static/media/logo.c53d91bd.svg) center center no-repeat;
    background-size: contain;
    height: 30px;
    width: 80px; }
    .header .logo-img span {
      display: none; }
  .header .menu-button {
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px; }
    .header .menu-button:hover {
      background-color: #E5E5E5; }
    .header .menu-button:after {
      content: '';
      display: block;
      height: 50px;
      width: 50px;
      background: transparent url(/static/media/icon-user.2e5bb019.svg) center center no-repeat; }
    .header .menu-button span {
      display: none; }

.menu {
  display: block;
  position: absolute;
  background-color: #FFFFFF;
  width: 280px;
  top: 0;
  right: 0;
  color: #5C2B8F; }
  .menu::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #FFFFFF;
    border-left-width: 25px;
    border-top-width: 25px;
    position: absolute;
    top: -10px;
    right: 20px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -o-transform: rotate(45deg); }
  .menu .modal {
    position: absolute;
    top: 0;
    right: 5px;
    width: 250px;
    background-color: #FFFFFF;
    text-align: center; }
    .menu .modal .name-user {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: bold;
      text-align: left;
      padding: 1.5rem;
      border-bottom: 1px solid #E5E5E5; }
    .menu .modal .menu-list {
      border-bottom: 1px solid #E5E5E5;
      padding: 1rem 0; }
      .menu .modal .menu-list .menu-item {
        padding: 1rem; }
    .menu .modal a {
      display: inline-block;
      text-decoration: none;
      color: #5C2B8F; }
    .menu .modal .exit {
      padding: 1.5rem; }

.Overlay {
  position: fixed;
  z-index: 102;
  top: 53px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); }

.droppingContent {
  z-index: 200;
  background-color: #F57F1E30; }

.app-container {
  flex-direction: column;
  align-items: center;
  justify-content: space-between; }
  .app-container .wrapper {
    height: 100%;
    width: 100%;
    padding: 1rem .5rem; }
    @media (min-width: 1060px) {
      .app-container .wrapper {
        max-width: 1060px; } }
    .app-container .wrapper.loading {
      position: relative; }
      .app-container .wrapper.loading:after {
        content: 'Carregando, aguarde...';
        font-size: 1rem;
        font-weight: bold;
        color: #49454E;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(245, 245, 245, 0.5) url(/static/media/spinner.beb0983b.svg) center 40% no-repeat;
        z-index: 500; }

.container-content {
  flex: 1 1;
  align-items: center;
  justify-content: flex-start;
  padding-top: 4rem; }
  @media (min-width: 1060px) {
    .container-content {
      padding-top: inherit; } }

.alert-box {
  width: 90%;
  min-width: 300px;
  max-width: 380px;
  min-height: 90px;
  top: 100px;
  right: 10px;
  position: fixed;
  background: #FFFAF6;
  border: 1px solid #F57F1E;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 600; }

.alert-box:before {
  content: '';
  background: transparent url(/static/media/icon-alert.50aa982d.svg) center center no-repeat;
  background-size: contain;
  display: block;
  width: 38px;
  margin: 0 1rem; }

.alert-close {
  background: transparent url(/static/media/icon-close.6ca7788e.svg) center center no-repeat;
  background-size: contain;
  display: block;
  width: 26px;
  height: 26px;
  margin-top: 10px;
  margin-right: 14px;
  cursor: pointer; }
  .alert-close span {
    display: none; }

.alert-content {
  max-width: 259px;
  display: block; }

.alert-title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  margin: .5rem 0;
  font-size: 18px;
  color: #F57F1E; }

.alert-description {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  font-size: 14px;
  padding-bottom: 1rem;
  color: #F57F1E; }

.alert-off {
  display: none; }

@media (min-width: 1060px) {
  .alert-box {
    top: 47px; } }

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  width: 100%;
  background-color: #5C2B8F;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: .5rem;
  border: none;
  cursor: pointer;
  box-shadow: 1px 2px 4px #9E9BA1;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px; }
  .button:hover {
    background-color: #6931a3;
    box-shadow: 1px 2px 4px #49454E; }
  .button:active {
    background-color: #4f257b;
    box-shadow: 1px 2px 4px #49454E inset; }
  .button-ghost {
    color: #49454E;
    background: inherit; }
    .button-ghost:hover {
      background-color: #f2f2f2;
      box-shadow: 1px 2px 4px #49454E; }
    .button-ghost:active {
      background-color: #d8d8d8;
      box-shadow: 1px 2px 4px #49454E inset; }
  .button.disabled {
    background-color: #9E9BA1;
    cursor: not-allowed;
    box-shadow: none; }
  .button svg {
    margin-right: 10px; }
  .button.loading span {
    display: none; }
  .button.loading:after {
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    background: transparent url(/static/media/spinner.beb0983b.svg) center center no-repeat;
    background-size: contain;
    margin: 0 auto; }

.button-next {
  align-items: center; }

.button-panel {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #DBDBDB;
  background: #E5E5E5;
  border-radius: 4px;
  padding: 1rem 1rem;
  margin: 2rem 0 0; }
  .button-panel .button {
    margin: 0; }

@media (min-width: 1060px) {
  .button {
    max-width: 250px; }
  .button-next {
    padding-top: 10px; } }

.footer {
  background: #FFFFFF;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #E5E5E5; }

.footer-content {
  align-items: center; }

.footer-cell {
  padding: 1rem 0;
  align-items: center; }

.footer-cell-logo {
  flex-direction: row;
  justify-content: center;
  padding: 1rem; }

.footer-small-message {
  font-size: 1rem;
  padding: 1rem 0;
  border-top: 1px solid #E5E5E5;
  text-align: center; }

.footer-info {
  font-size: 1rem;
  line-height: 1.5rem;
  width: 65%;
  max-width: 200px;
  margin-left: 1rem;
  color: #5C2B8F; }

.footer-logo {
  background: transparent url(/static/media/logo.c53d91bd.svg) center center no-repeat;
  background-size: contain;
  width: 80px;
  height: 40px;
  margin-bottom: .5rem; }
  .footer-logo span {
    display: none; }

.footer-title {
  color: #5C2B8F;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: bold;
  padding: .25rem 0;
  text-align: center;
  margin-bottom: .5rem; }

.footer-link {
  color: #5C2B8F;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  padding: .25rem 0; }

.footer-social {
  display: flex;
  justify-content: space-between;
  width: 60px; }

.footer-social-twitter {
  background: transparent url(/static/media/icon-twitter.1ea08021.svg) center center no-repeat;
  background-size: contain;
  display: block;
  width: 24px;
  height: 20px; }
  .footer-social-twitter span {
    display: none; }

.footer-social-instagram {
  background: transparent url(/static/media/icon-instagram.c07e6a2c.svg) center center no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  display: block; }
  .footer-social-instagram span {
    display: none; }

.footer-appstore {
  display: flex; }

.footer-appstore-apple {
  background: transparent url(/static/media/app-store.9e4c0abc.svg) center center no-repeat;
  background-size: contain;
  width: 106px;
  height: 30px;
  display: block; }
  .footer-appstore-apple span {
    display: none; }

.footer-appstore-google {
  background: transparent url(/static/media/google-play.048f789b.svg) center center no-repeat;
  background-size: contain;
  width: 106px;
  height: 30px;
  display: block; }
  .footer-appstore-google span {
    display: none; }

@media (min-width: 1060px) {
  .footer-content {
    flex-direction: row; } }

.form-input {
  padding: 0.5rem;
  background-color: #F5F5F5;
  outline: none;
  border: 1px solid #E5E5E5;
  -webkit-transition: border 200ms;
  transition: border 200ms; }
  .form-input[type="text"]:focus, .form-input[type="password"]:focus, .form-input[type="email"]:focus, .form-input[type="date"]:focus {
    border-color: #5C2B8F; }
  .form-input[type="checkbox"]:focus, .form-input[type="radio"]:focus {
    outline: #5C2B8F dashed 1px; }
  .form-input[disabled] {
    cursor: not-allowed;
    color: #9E9BA1; }
  .form-input[readonly] {
    color: #9E9BA1; }
  .form-input.error {
    border-color: #F57F1E !important;
    background: #feeee1; }

.form-input-wrapper {
  margin-bottom: 1rem; }

.form-option-wrapper {
  margin-bottom: 1rem; }

.form-label {
  font-family: "Open Sans", sans-serif;
  padding: 0.25rem 0;
  font-size: 1rem; }

.form-zipcode {
  margin-bottom: 1rem; }

.zipcode-wrapper,
.cupom-wrapper {
  flex-direction: row; }

.zipcode-title {
  display: block;
  margin-bottom: 0.5rem; }

.form-validation {
  padding: 2px;
  color: #F57F1E;
  font-weight: bold; }

.zipcode,
.cupom {
  margin: 0;
  box-shadow: none; }
  .zipcode + button,
  .cupom + button {
    text-transform: capitalize;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    -o-border-radius: 0 3px 3px 0; }
    .zipcode + button:hover,
    .cupom + button:hover {
      box-shadow: 3px 3px 10px #49454E; }
    .zipcode + button span,
    .cupom + button span {
      display: block;
      padding: 0; }

.card-name {
  text-transform: uppercase; }

.box-data-card {
  flex-direction: row;
  align-items: flex-end;
  justify-content: start; }

.data-card {
  margin: 1rem; }
  .data-card.month {
    margin-left: 0;
    margin-right: 0;
    width: 60px; }
  .data-card.year {
    width: 60px;
    margin-right: 0; }
  .data-card.cvv {
    width: 50px;
    margin-right: 0; }
  .data-card.installment {
    width: 110px;
    margin-right: 0; }

input.form-input,
select.form-input {
  font-size: 1.1rem;
  font-family: "Open Sans", sans-serif;
  border-radius: 0;
  background: #F5F5F5; }

select.form-input {
  -webkit-appearance: none;
  background: #F5F5F5 url(/static/media/select-arrow.730be13e.svg) center center no-repeat;
  background-repeat: no-repeat;
  background-position: center right; }
  select.form-input:focus {
    border-color: #5C2B8F; }

.form-gift {
  display: block;
  width: 200px;
  margin: 0 auto; }

.photo-cell {
  width: 186px;
  align-items: center;
  margin: 10px; }

.photo-cell-holder {
  position: relative;
  height: 186px;
  width: 186px; }
  .photo-cell-holder.low-resolution:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 6px;
    right: 5px;
    width: 40px;
    height: 40px;
    background: transparent url(/static/media/lowres.f236f027.png) center center no-repeat;
    background-size: cover;
    z-index: 106; }
  .photo-cell-holder:after {
    content: '';
    background: transparent url(/static/media/picture_holder.ce9a95f9.png) center center no-repeat;
    background-size: cover;
    width: 186px;
    height: 184px;
    position: absolute;
    top: 1px;
    left: 0;
    z-index: 100; }
  .photo-cell-holder .photo-cell-button-add {
    background: #5C2B8F;
    width: 181px;
    height: 180px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    z-index: 101;
    cursor: pointer; }
    .photo-cell-holder .photo-cell-button-add:before {
      content: '';
      display: block;
      background: transparent url(/static/media/icon-plus.b09b5c28.svg) center center no-repeat;
      position: absolute;
      width: 40px;
      height: 94px; }
    .photo-cell-holder .photo-cell-button-add .photo-cell-button-text {
      color: #FFFFFF;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      font-size: 1.3rem;
      text-align: center; }

.photo-cell-image {
  width: 180px;
  height: 180px;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 10; }

.photo-cell-actions {
  width: 186px;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 4px; }

.photo-cell-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 90px;
  min-height: 26px;
  color: #FFFFFF;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px; }
  .photo-cell-button .photo-cell-button-icon {
    margin-right: 6px;
    padding-bottom: 1px; }
  .photo-cell-button .photo-cell-button-text {
    display: block;
    text-align: center;
    padding-bottom: 1px; }

.photo-cell-button-edit {
  background: #5C2B8F; }
  .photo-cell-button-edit:hover {
    background: #431f68; }

.photo-cell-button-remove {
  background: #F57F1E; }
  .photo-cell-button-remove:hover {
    background: #d6660a; }

.photo-cell-copies {
  padding-top: 10px; }

.photo-cell-button-plus, .photo-cell-button-minus {
  background: #5C2B8F;
  width: 25px;
  height: 25px; }
  .photo-cell-button-plus:hover, .photo-cell-button-minus:hover {
    background: #431f68; }

.photo-cell-quantity {
  flex: 0.8 1;
  background: #E5E5E5;
  padding: 7px;
  text-align: center;
  font-style: italic;
  color: #49454E;
  overflow: hidden;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px; }

.status-bar {
  background: #F57F1E;
  padding: .5rem;
  color: #FFFFFF; }

.page-title {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.36rem;
  line-height: 1.6rem;
  text-align: center;
  color: #5C2B8F;
  margin-bottom: 1rem; }

.page-title2 {
  font-size: 2rem;
  line-height: 2rem; }

.panel-default {
  background-color: #FFFFFF;
  border: 1px solid #E5E5E5;
  margin-bottom: .5rem;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px; }

.panel-default-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
  min-height: 40px;
  border-bottom: 1px solid #E5E5E5;
  color: #5C2B8F;
  font-weight: bold;
  font-size: 1.3rem; }

.panel-default-content {
  padding: 1rem; }

.panel-horizontal {
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1rem; }

.panel-vertical {
  align-items: center;
  margin-bottom: 1rem; }

.panel-photolist {
  flex-flow: row wrap;
  justify-content: center; }

@media (min-width: 1060px) {
  .panel-default-6 {
    max-width: 48%; }
  .panel-default-4 {
    max-width: 33%; }
  .panel-default-3 {
    max-width: 24%; }
  .panel-photolist {
    justify-content: flex-start;
    padding: 0; } }

.option-selector {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.option-selector .input-option {
  position: absolute;
  opacity: 0; }

.option-content {
  flex-direction: column; }

.checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: #E5E5E5;
  border-radius: 50%;
  margin-right: 20px; }
  .checkmark.-delivery-info {
    height: 31.5%; }

.checkmark::before {
  content: "";
  position: absolute;
  display: block;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #FFFFFF; }

.option-selector.checked .checkmark {
  background-color: #CFC2DC;
  height: 20px;
  width: 20px; }
  .option-selector.checked .checkmark.-delivery-info {
    height: 31.5%; }

.option-selector:hover .checkmark {
  height: 20px;
  width: 20px;
  background-color: #CFC2DC; }
  .option-selector:hover .checkmark.-delivery-info {
    height: 31.5%; }

.checkmark:after {
  content: "";
  position: absolute;
  display: block; }

.option-selector.checked .checkmark:after {
  display: block;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #5C2B8F; }

.option-title {
  display: block;
  color: #5C2B8F;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 10px; }

.option-text {
  display: block;
  color: #5C2B8F;
  font-size: 1.1rem; }

.cropper-holder {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  padding: 1rem; }

.cropper-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center; }
  @media (min-width: 1060px) {
    .cropper-container {
      width: 500px;
      height: 500px; } }
  .cropper-container > div {
    border: 1px solid #49454E; }

.delivery-option {
  border: 1px solid #E5E5E5; }
  .delivery-option:last-child {
    border-top: none; }

.address {
  background-color: #FFFFFF; }
  .address-box {
    border: 1px solid #E5E5E5;
    justify-content: center;
    padding: 1rem; }
  .address-item {
    display: block;
    line-height: 2rem;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px; }
    .address-item-master {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.3em; }

@media (min-width: 1060px) {
  .delivery, .order-box {
    flex-direction: row;
    justify-content: space-between; } }

.order {
  color: #5C2B8F; }
  .order-box {
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    max-width: 700px;
    width: 95%;
    margin: 0 auto;
    align-items: center; }
    .order-box .order-col:first-child {
      width: 30%;
      min-width: 184px; }
    .order-box .order-col .product-image {
      display: block;
      background: transparent url(/static/media/deliverybox.fd1e9099.png) center center no-repeat;
      background-size: contain;
      width: 184px;
      height: 128px; }
      .order-box .order-col .product-image span {
        display: none; }
  .order-table {
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-size: 18px;
    min-width: 280px;
    width: 90%;
    margin: 0 1rem; }
  .order-td {
    width: 65%;
    padding: 1.5rem .5rem;
    line-height: 2rem; }
    .order-td strong {
      display: inline-block;
      font-weight: bold; }
  .order-info {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    width: 90%;
    margin: 1rem auto; }
    .order-info a {
      text-decoration: none;
      color: #49454E; }
      .order-info a:hover {
        text-decoration: underline; }
    .order-info p {
      line-height: normal;
      margin: 1rem 0; }
      .order-info p span {
        font-weight: bold; }
    .order-info-p {
      font-size: 1.4rem; }
      .order-info-p-strong {
        font-size: 1.4rem;
        font-weight: bold; }
    .order-info-attention {
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      font-size: 1rem;
      text-align: center; }

@media (min-width: 1060px) {
  .order-info {
    width: 60%; } }

.login {
  background-color: #FFFFFF;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  padding: .7rem 1rem;
  font-size: 1.85rem !important; }
  .login-box {
    background-color: #FFFFFF;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    padding: 1rem;
    margin: 1rem;
    width: 92%;
    min-height: 100px;
    align-items: center; }
  .login-content {
    width: 90%;
    max-width: 450px; }
    .login-content .button {
      max-width: 100%;
      margin-right: 0;
      margin-left: 0;
      font-weight: bold; }
  .login-reset, .login-register {
    font-size: 1.2rem;
    color: #5C2B8F;
    text-decoration: none; }
    .login-reset:hover, .login-register:hover {
      text-decoration: underline; }
  .login-register {
    padding: 1rem;
    text-align: center; }

.my-order {
  width: 95%;
  align-items: center;
  margin: 0 auto; }

.order-col .button {
  margin-right: 0;
  margin-left: 0; }

.my-order-box {
  border: none;
  color: #5C2B8F; }

.my-order-status {
  text-align: center;
  font-size: 1.1rem;
  text-transform: none;
  text-transform: initial; }

.my-order-table {
  margin: 0; }

.top-border {
  border-top: 1px solid #E5E5E5; }

.my-order-status-bar {
  display: block;
  margin: 1rem auto;
  position: relative;
  height: 8px;
  width: 100%;
  background-color: #E5E5E5; }
  .my-order-status-bar-progress {
    display: block;
    height: 8px; }
  .my-order-status-bar .bar-success {
    background-color: #8FEC57;
    width: 50%;
    animation: bar-success 2s;
    -webkit-animation: bar-success 2s; }
  .my-order-status-bar .bar-denied {
    background-color: #F57F1E;
    width: 25%;
    animation: bar-denied 2s;
    -webkit-animation: bar-denied 2s; }

@-webkit-keyframes bar-success {
  0% {
    width: 0%; }
  100% {
    width: 50%; } }

@keyframes bar-success {
  0% {
    width: 0%; }
  100% {
    width: 50%; } }

@-webkit-keyframes bar-denied {
  0% {
    width: 0%; }
  100% {
    width: 25%; } }

@keyframes bar-denied {
  0% {
    width: 0%; }
  100% {
    width: 25%; } }

.my-order-title {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  line-height: 2rem;
  font-family: "Open Sans", sans-serif; }

.my-order-title-past {
  font-size: 1rem; }

.purchase-date {
  font-weight: normal;
  font-size: 1rem; }

.bold {
  font-weight: bold; }

@media (min-width: 1060px) {
  .my-order-table {
    margin: 0 1rem 0 7rem; } }

.resume {
  font-family: "Open Sans", sans-serif;
  color: #49454E;
  justify-content: space-between; }
  .resume-box {
    background-color: #FFFFFF;
    width: 100%;
    border: 1px solid #E5E5E5;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    padding: 1rem;
    margin: .5rem 0;
    min-height: 141px; }
  .resume-title {
    margin-bottom: 1rem;
    font-size: 1.1rem; }
  .resume-text {
    font-size: 1rem;
    line-height: 1.5rem; }
  .resume-ship, .resume-name {
    font-size: 1.1rem; }

.detail-button {
  width: 100%; }
  .detail-button .button {
    font-weight: bold;
    margin: .5rem 0;
    max-width: 100%; }

@media (min-width: 1060px) {
  .resume {
    flex-direction: row;
    margin-top: 2rem; }
    .resume-box {
      width: 49%; }
  .detail-button {
    margin-top: 1rem;
    flex-direction: row; }
    .detail-button .button {
      margin: 1rem 1.5rem;
      max-width: 240px; }
      .detail-button .button:first-child {
        margin-left: 0; } }

.personal-data {
  padding: 1rem 1rem; }

@media (min-width: 1060px) {
  .personal-data {
    flex-direction: row; } }

.lost-pass-text, .reset-pass-text {
  font-size: 1.3rem;
  color: #5C2B8F;
  text-align: center;
  margin: 5rem 0; }

.icon-success {
  background: transparent url(/static/media/icon-success.c8e5c5b0.svg) center center no-repeat;
  display: block;
  width: 100px;
  height: 100px;
  margin: 2rem auto 0;
  opacity: 1;
  animation: icon-success 1s;
  -webkit-animation: icon-success 1s; }

@-webkit-keyframes icon-success {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes icon-success {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.register-title {
  color: #5C2B8F;
  font-size: 1.5rem;
  text-align: center;
  line-height: 3rem; }

.starting-panel {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #E5E5E5;
  padding: 2rem;
  border-radius: 3px;
  min-height: 300px; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .starting-panel:before {
    -webkit-animation: spin 4s infinite linear;
            animation: spin 4s infinite linear;
    content: '';
    display: block;
    background: transparent url(/static/media/icon-cog.14b6b49a.svg) center center no-repeat;
    width: 80px;
    height: 80px; }
  .starting-panel p {
    font-size: 1.4rem;
    line-height: 2rem;
    margin: 1rem 0;
    text-align: center;
    width: 100%; }

.coupon-box {
  border: 1px solid #5C2B8F;
  background: #c2a3e3;
  color: #2a1341;
  font-weight: bold;
  text-transform: uppercase;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 3px; }
  .coupon-box p {
    font-size: 1rem;
    line-height: 1.5rem; }
    .coupon-box p.coupon-value {
      font-size: 1.4rem;
      line-height: 2rem; }

.coupon-box.coupon-invalid {
  border-color: #F57F1E;
  background: #feeee1;
  color: #a64f07;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase; }

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background: #FFFFFF;
  border: 1px solid #F5F5F5; }
  .intro h3 {
    text-align: center;
    font-size: 2rem;
    line-height: 2.3rem;
    margin-bottom: 1rem; }
  .intro p {
    text-align: center;
    font-size: 1rem;
    line-height: 1.4rem; }

.intro-image {
  display: block;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 450px; }

.breadcrumb {
  border: 1px solid #dbdbdb;
  background: #e5e5e5;
  border-radius: 4px;
  margin-bottom: 2rem;
  justify-content: center;
  min-height: 100px; }
  .breadcrumb .bc_cells {
    justify-content: center;
    flex-direction: row;
    margin: 0.25rem 0; }
  .breadcrumb .bc_cell {
    background: #f5f5f5;
    border: 1px solid #dbdbdb;
    width: 90px;
    min-height: 60px;
    margin: 0 0.15rem;
    align-items: center;
    justify-content: center; }
    .breadcrumb .bc_cell:before {
      content: "";
      display: block;
      width: 40px;
      height: 20px; }
      @media (min-width: 1060px) {
        .breadcrumb .bc_cell:before {
          margin-left: 1rem; } }
    @media (min-width: 1060px) {
      .breadcrumb .bc_cell {
        width: 100%;
        max-width: 174px;
        flex-direction: row; } }
  .breadcrumb .bc_product:before {
    background: transparent url(/static/media/bc_icon_frame.5386110b.svg) center center no-repeat;
    background-size: contain; }
  .breadcrumb .bc_freight:before {
    background: transparent url(/static/media/bc_icon_freight.d1e238fb.svg) center center no-repeat;
    background-size: contain; }
  .breadcrumb .bc_discount:before {
    background: transparent url(/static/media/bc_icon_discount.0e9f41a7.svg) center center no-repeat;
    background-size: contain; }
  .breadcrumb .bc_total:before {
    background: transparent url(/static/media/bc_icon_total.a07aa514.svg) center center no-repeat;
    background-size: contain; }
  .breadcrumb .bc_title {
    display: none;
    text-align: center;
    margin: 0.4rem 0 0;
    font-size: 12px;
    color: #7d7d7d; }
    @media (min-width: 1060px) {
      .breadcrumb .bc_title {
        display: block;
        text-align: left; } }
  .breadcrumb .bc_value {
    text-align: center;
    margin: 0.4rem 0 0;
    font-size: 12px;
    font-weight: bold;
    color: #7d7d7d; }
    @media (min-width: 1060px) {
      .breadcrumb .bc_value {
        text-align: left; } }
  @media (min-width: 1060px) {
    .breadcrumb .bc_cell_content {
      margin-left: 1rem; } }
  .breadcrumb .bc_text {
    text-align: center;
    padding-top: 0.32rem;
    font-weight: bold; }

.post-content h1, .post-content h2, .post-content h3, .post-content h4, .post-content h5, .post-content h6 {
  font-weight: bold;
  margin-bottom: 2rem;
  margin-top: 3rem;
  font-size: 2rem;
  line-height: 2.3rem; }

.post-content h2 {
  margin-bottom: 1.8rem;
  margin-top: 2rem;
  font-size: 1.8rem;
  line-height: 2rem; }

.post-content h3 {
  margin-bottom: 1.8rem;
  margin-top: 2rem;
  font-size: 1.6rem;
  line-height: 1.8rem; }

.post-content h4 {
  margin-bottom: 1.8rem;
  margin-top: 2rem;
  font-size: 1.5rem;
  line-height: 1.7rem; }

.post-content p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 1rem; }

.post-content .mb {
  font-size: 3rem;
  line-height: 3.5rem;
  padding: 1rem 0;
  border-bottom: 10px solid #F57F1E;
  color: #5C2B8F; }

.cards-list {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row; }

.card {
  position: relative;
  margin: 1rem auto;
  width: 320px;
  height: 320px;
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s; }
  .card:hover {
    -webkit-transform: scale(0.9, 0.9);
            transform: scale(0.9, 0.9);
    box-shadow: 5px 5px 5px 5px rgba(80, 76, 83, 0.2), -5px -5px 5px 5px rgba(80, 76, 83, 0.2); }

.card .card_image {
  width: inherit;
  height: inherit; }

.card .card_image img {
  width: inherit;
  height: inherit;
  object-fit: cover; }

.card .card_title {
  text-align: center;
  font-family: sans-serif;
  font-weight: bold;
  height: 40px;
  font-size: 28px;
  margin-top: 8px; }

.title-white {
  font-size: 30px;
  color: white; }

.subtitle-white {
  color: white;
  font-size: 24px; }

.title-black {
  color: black; }

.card-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.card-bottom {
  position: absolute;
  bottom: 10px; }

@media all and (max-width: 500px) {
  .card-list {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column; } }

