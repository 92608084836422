.login{
  background-color: $color_white;
  @include border-radius(4px);
  padding: .7rem 1rem;
  font-size: 1.85rem!important;
  &-box{
    background-color: $color_white;
    @include border-radius();
    padding: 1rem;
    margin: 1rem;
    width: 92%;
    min-height: 100px;
    align-items: center;
  }
  &-content{
    width: 90%;
    max-width: 450px;
    .button{
      max-width: 100%;
      margin-right: 0;
      margin-left: 0;
      font-weight: bold;
    }
  }
  &-reset, &-register{
    font-size: 1.2rem;
    color: $color_primary;
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }
  &-register{
    padding: 1rem;
    text-align: center;
  }
}