.breadcrumb {
	border: 1px solid #dbdbdb;
	background: #e5e5e5;
	border-radius: 4px;
	margin-bottom: 2rem;
	justify-content: center;
	min-height: 100px;

	.bc_content {
	}

	.bc_cells {
		justify-content: center;
		flex-direction: row;
		margin: 0.25rem 0;
	}

	.bc_cell {
		background: #f5f5f5;
		border: 1px solid #dbdbdb;
		width: 90px;
		min-height: 60px;
		margin: 0 0.15rem;
		align-items: center;
		justify-content: center;

		&:before {
			content: "";
			display: block;
			width: 40px;
			height: 20px;
			@include desktop {
				margin-left: 1rem;
			}
		}

		@include desktop {
			width: 100%;
			max-width: 174px;
			flex-direction: row;
		}
	}

	.bc_product:before {
		@include background_image("bc_icon_frame.svg");
		background-size: contain;
	}

	.bc_freight:before {
		@include background_image("bc_icon_freight.svg");
		background-size: contain;
	}

	.bc_discount:before {
		@include background_image("bc_icon_discount.svg");
		background-size: contain;
	}

	.bc_total:before {
		@include background_image("bc_icon_total.svg");
		background-size: contain;
	}

	.bc_title {
		display: none;
		text-align: center;
		margin: 0.4rem 0 0;
		font-size: 12px;
		color: #7d7d7d;
		@include desktop {
			display: block;
			text-align: left;
		}
	}

	.bc_value {
		text-align: center;
		margin: 0.4rem 0 0;
		font-size: 12px;
		font-weight: bold;
		color: #7d7d7d;
		@include desktop {
			text-align: left;
		}
	}

	.bc_cell_content {
		@include desktop {
			margin-left: 1rem;
		}
	}

	.bc_text {
		text-align: center;
		padding-top: 0.32rem;
		font-weight: bold;
	}
}
