
.my-order{
  width: 95%;
  align-items: center;
  margin: 0 auto;
}
.order-col{
  .button{
    margin-right: 0;
      margin-left: 0;
  }
}
.my-order-box{
  border: none;
  color: $color_primary;

}
.my-order-status{
  text-align: center;
  font-size: 1.1rem;
  text-transform: initial;
}
.my-order-table{
  margin: 0;
}
.top-border{
  border-top: 1px solid $color_gray-2;
}
.my-order-status-bar{
  display: block;
  margin: 1rem auto;
  position: relative;
  height: 8px;
  width: 100%;
  background-color: $color_gray-2;
  
  &-progress{
    display: block;
    height: 8px;
    
}
.bar-success{
  background-color: $color_progress;
  width: 50%;
  animation: bar-success 2s;
    -webkit-animation: bar-success 2s;
} 
.bar-denied{
  background-color: $color_secondary;
  width: 25%;
  animation: bar-denied 2s;
  -webkit-animation: bar-denied 2s;
}

}
@keyframes bar-success{
  0% {width: 0%}
  100% {width: 50%}
}
@keyframes bar-denied{
  0% {width: 0%}
  100% {width: 25%}
}

.my-order-title{
  display: inline-block;
  font-weight: normal;
  text-align: center;
  line-height: 2rem;
  font-family: "Open Sans", sans-serif;

}
.my-order-title-past{
  font-size: 1rem;
}
.purchase-date{
    font-weight: normal;
    font-size: 1rem;
}

.bold{
  font-weight: bold;
}

@include desktop {
  .my-order-table{
    margin: 0 1rem 0 7rem;
  }
}