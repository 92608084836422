.page-title {
	font-family: "Open Sans", sans-serif;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1.36rem;
	line-height: 1.6rem;
	text-align: center;
	color: $color_primary;
	margin-bottom: 1rem;
}

.page-title2 {
	font-size: 2rem;
	line-height: 2rem;
}
