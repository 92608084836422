.starting-panel {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $color-gray-2;
  padding: 2rem;
  border-radius: 3px;
  min-height: 300px;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &:before {
    animation: spin 4s infinite linear;
    content: '';
    display: block;
    @include background_image('icon-cog.svg');
    width: 80px;
    height: 80px;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    margin: 1rem 0;
    text-align: center;
    width: 100%;
  }
}