
header {
  @include background_image("adventure-2679498_1920.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom center;
  min-height: 16rem;
  padding: 1rem;
  border-bottom: 20px solid #f17e20;
}

header .site-name {
  @include background_image("logo.svg");
  background-size: contain;
  display: block;
  width: 140px;
  height: 57px;
  margin: 1rem auto 2rem;
  padding: 0;
}

header .site-subtitle {
  font-size: 1.2rem;
  color: #5c2d91;
  background-color: #ffffffe8;
  padding: 1rem;
}

header .site-subtitle:after,
header .site-subtitle:before {
  content: '  ';
}

.dashed:before,
.dashed:after {
  content: '';
  width: 70%;
  display: block;
  margin: 1rem auto;
  border-top: 1px solid #f5f5f5;
}

header .site-name span {
  display: none;
}

.content {
  margin: 0 auto;
}

.content .intro h2 {
  font-family: "Lato", sans-serif;
  color: #fff;
  background-color: #f17e20c7;
  padding: 1rem;
  font-size: 1.2rem;
  line-height: 140%;
  margin: 0;
}

.content .preview {
  padding: 2rem .5rem;
  border-bottom: 20px solid #5b2c8f;
}

.content .preview .smartphone {
  content: '';
  display: block;
  @include background_image("preview.png");

  background-size: contain;
  width: 250px;
  height: 117px;
  margin: 1rem auto;
}

.content .preview:after {
  content: '';
  display: block;
  clear: both;
}

.content .links {
  margin: 0 auto 1rem;
  align-items: center;
  justify-content: center;
}

.content .links:after {
  content: '';
  display: block;
  clear: both;
}

.content .links .store-link {
  display: inline-block;
  width: 100px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: .5rem;
}

.content .links .store-link span {
  display: none;
}

.content .links .store-link.store-apple {
  display: inline-block;
  overflow: hidden;
  @include background_image("appstore-lrg.svg");
  background-size: contain;
}

.content .links .store-link.store-android {
  @include background_image("google-play.png");
  background-size: contain;
}

.content .links .store-link.store-webapp {
  @include background_image("badge_online.png");
  background-size: contain;
  width: 250px;
  height: 65px;
}

.content .highlight {
  @include background_image("highlight.jpg", #ccc);
  background-size: cover;
  min-height: 100px;
  padding: 2rem .5rem;
  color: #fff;
  text-align: center;
}

.content .differences {
  padding: 2rem .5rem;
  text-align: center;
}

.content .differences h4 {
   font-size: 1.7rem;
}

.content .examples {
  padding: 2rem .5rem;
  @include background_image("bg-2.jpg", transparent, top, center);
  background-size: cover;
  min-height: 23rem;
}

.content .examples:after {
  content: '';
  display: block;
  clear: both;
}

.content .examples img {
  display: block;
  margin: .5rem auto;
  max-width: 50%;
  float: left;
}

.content .faq {
  padding: 2rem 2rem;
  text-align: center;
}

.content .faq h3 {
  padding: 1rem;
  margin-top: 2rem;
}

.content .faq p {
  margin: 0 auto;
}

.content .faq p:after {
  content: '';
  display: block;
  width: 40%;
  margin: 2rem auto 1rem;
  border-top: 1px solid #e5e5e5;
}

.content .faq p:before {
  content: '';
  display: block;
  width: 40%;
  margin: 1rem auto 2rem;
  border-top: 1px solid #e5e5e5;
}

footer {
  padding: 2rem .5rem;
  margin: 0;
  background-color: #5c2d91;
}

footer a {
  color: #fff;
  text-decoration: none;
}

footer h2 {

}

footer h5 {
  font-size: 1rem;
}

footer .rights {
  font-size: .8rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

table tr {

}

table th,
table td {
  padding: .5rem;
  border: 1px solid #e5e5e5;
  text-align: center;
}

table th {
  background-color: #e5e5e5;
}

.terms {

  padding: 1rem;

  h2 {
    font-size: 120%;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }
}
